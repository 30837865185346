import React from 'react';

import withRoot from '../../utils/withRoot';
import SEO from '../../components/Seo';
import Page from '../../components/Page';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import colors from '../../mui_theme/colors';

const styles = theme => ({
  root: {
    height: '100%',
    padding: 32,
    margin: '80px auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    display: 'block !important',
  },
  author: {
    fontFamily: 'Muli',
    fontSize: 12,
    fontWeight: 800,
    lineHeight: '1.58',
    color: colors.pinkishGrey,
  },
});

function MenstrualCycleData(props) {
  const { classes } = props;

  return (
    <Page>
      <SEO title="You can learn a lot from your menstrual cycle data" />

      <Grid container spacing={8} className={classes.root}>
        <Grid item xs={12}>
          <h6 className={classNames('mb-4', classes.author)}>
            Author: Tony Morrison, Founder, Motive Biosciences Inc.,
            tony@motivebio.com, +1.832.418.1622
          </h6>
          <h1 className={classNames('typography-title mb-4', classes.title)}>
            You can learn a lot from your menstrual cycle data
          </h1>
          <h6 className={classNames('typography-header mb-4')}>
            Read time: 8 min.
          </h6>
        </Grid>
        <Grid item xs={12}>
          <h4 className="typography-subtitle mb-4">Introduction</h4>
          <p className="typography-p mb-2">
            Hormone imbalance and reproductive health issues are often ignored
            until it matters. This is mainly because medical diagnosis and
            testing is inconvenient and costly. The simplest signs and symptoms
            of potential reproductive health issues are irregular periods and
            menstrual cycle patterns. It’s the body’s way of telling us that
            something is not quite right. Most young women will fall into a
            usual and predictable menstrual cycle pattern within a few years
            after their first period. Occasionally, she may experience an
            irregular period such as a long cycle, an absent period, or a short
            cycle resulting in two periods in the same monthly cycle. An
            occasional irregular period in most cases is not serious. However,
            progressively irregular cycles, especially if coupled with other
            physical signs and symptoms, may be serious enough to discuss with
            your physician.
          </p>
          <p className="typography-p mb-2">
            Whether you are trying to get pregnant or not, poor reproductive
            health is associated with an increased risk of a number of long-term
            chronic diseases and neglecting healthcare can lead to long-term
            health problems and poor quality of life. You can learn a lot about
            your fertility status and overall reproductive health from the
            regularity of your menstrual cycle, including the duration, timing
            and intensity of your periods and the length of time between
            periods.
          </p>

          <p className="typography-p mb-4">
            In this article, we will describe possible causes of irregular
            cycles and how to monitor and analyze your menstrual cycle health.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h4 className="typography-subtitle mb-4">The menstrual cycle</h4>
          <p className="typography-p mb-2">
            A “regular” menstrual cycle is 28 days long and consists of two main
            phases. The follicular phase starts on day 1 of your period start
            day and continues to the ovulation at day 14. The luteal phase
            starts at day 15, right after ovulation, and continues to day 28 of
            a regular cycle. Periods and menstrual cycles are often not that
            regular though. A normal menstrual period can range from 2-8 days in
            duration, and a normal menstrual cycle range could be between 21-35
            days in length. The menstrual cycle length is determined by counting
            from the first day of your last period start to the day before the
            first day of your current period start.
          </p>
          <p className="typography-p mb-2">
            Missed or irregular periods and menstrual cycle lengths may cause a
            woman to worry that she is pregnant. Irregularities such as spotting
            or bleeding events, especially if shorter than 2 days may not be a
            menstrual period at all. They may be signs of a reproductive
            disorder or hormonal imbalance. Bleeding longer than 8 days or any
            heavy, persistent bleeding could be a sign of an underlying illness
            or reproductive complication. Likewise, a frequently short or
            exceedingly long menstrual cycle length also could be an indication
            of potential reproductive health issues.
          </p>

          <p className="typography-p mb-2">
            The MotiveBio personalized fertility healthtech application helps
            women to track the days of the menstrual cycle and to observe and
            record physical symptoms and fertility signs. This information is
            often used to conceive by timing unprotected sex during the days
            around the likely ovulation day identified as the fertile window.
            Some will use this information to avoid pregnancy by not having
            unprotected sex during those same fertile days. We developed a
            variation of the calendar-based method of Knaus–Ogino (1) and
            combine physical symptoms and fertility signs to predict the
            ovulation day and likely fertile window.
          </p>

          <p className="typography-p mb-2">
            We define five distinct characteristics of the menstrual cycle to be
            aware as an aid to women in planning and monitoring her menstrual
            cycle characteristics.
          </p>
          <p className="typography-p mb-4">
            Figure 1 shows the 5 discernible phases of the menstrual cycle by
            day relative to the period start, end and likely ovulation day.
          </p>
        </Grid>

        <Grid item xs={12} md={5}>
          <p className="typography-p mb-2">1. Menstrual period</p>
          <p className="typography-p mb-2">2. Follicular phase</p>
          <p className="typography-p mb-2">3. Fertile window</p>
          <p className="typography-p mb-2">4. Ovulation day</p>
          <p className="typography-p mb-4">5. Luteal phase</p>
        </Grid>
        <Grid item xs={12} md={7}>
          [Place for image]
        </Grid>

        <Grid item xs={12}>
          <p className="typography-p mb-2">
            For any calendar, or counting, method of fertility awareness, the
            methods are only accurate for consistent and regular cycles between
            22-34 days. When cycles are shorter or longer, the ovulation day and
            fertile window might not be predicted as accurately. Inconsistency
            and irregularity are potential indicators of reproductive health
            issues and hormonal imbalances that warrant deeper investigation.
          </p>
          <p className="typography-p mb-2">
            Figure 2 depicts hormone profiles for AMH, FSH, LH, E2, and P4 by
            day of the menstrual cycle relative to the day of ovulation, day 14.
          </p>

          <p className="typography-p mb-2">[Place for image]</p>

          <p className="typography-p mb-4">
            For example, a menstrual cycle that is shorter than 21 days may be
            indicative of diminished ovarian reserve and associated with ovarian
            aging. Hormone determinations will generally show that estradiol
            (E2) and progesterone (P4) levels will be lower. Anti-Müllerian
            hormone (AMH) also will be lower. A menstrual cycle length longer
            than 35 days may be indicative of oligomenorrhea, or anovulation,
            which are signs of polycystic ovary syndrome (PCOS). Oligomenorrhea
            is generally associated with high E2, low P4, and AMH in most cases
            will be elevated. Anovulation may result in a menstrual cycle with
            either no menstrual period or a prolonged period. Endometrial
            receptivity could be an issue in cases of oligomenorrhea accompanied
            by a drop in P4.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h4 className="typography-subtitle mb-4">
            Possible causes of irregular cycles
          </h4>
          <p className="typography-p mb-4">
            The following are the most common reasons women may experience an
            irregular cycle.
          </p>

          <h5 className="typography-subtitle mb-4">Pregnancy</h5>

          <p className="typography-p mb-4">
            The first most obvious reason for an unexpected missed or absent
            period is pregnancy. Once an egg is fertilized and implants in the
            uterus, the menstrual cycle just stops. Menstruation will not occur
            again for the duration of the pregnancy or while lactating for as
            long as she continues to breastfeed. Any bleeding after pregnancy
            has been confirmed should be treated seriously, and you should
            immediately see your healthcare provider.
          </p>

          <h5 className="typography-subtitle mb-4">Stress</h5>

          <p className="typography-p mb-4">
            Stress is one of the most common causes of an irregular period.
            Imagine you are late and worried that you are pregnant, so you
            become even more stressed. The stress hormone cortisol directly
            impedes gonadal (ovarian) function. It reduces how much estrogen and
            progesterone is produced during the cycle and can cause the timing
            of their menstrual cycle to change.
          </p>
          <p className="typography-p mb-4">[Place for image]</p>

          <p className="typography-p mb-4">
            During a normal regular cycle, the hypothalamus signals the
            pituitary gland with an increase in FSH, and LH which acts on the
            ovaries to recruit and mature follicles. The ovaries in turn while
            maturing the oocyte (egg) produce hormones that will prepare the
            uterus to receive a fertilized egg. Increases in stress related to
            work or health worries, sleeplessness, even diet and intense
            exercise can result in inhibition of hormonal signals to the ovaries
            and uterus.
          </p>

          <p className="typography-p mb-4">[Place for image]</p>

          <h4 className="typography-subtitle mb-4">Diet and Exercise</h4>
          <p className="typography-p mb-2">
            Diet and healthy weight are also potential causes irregular periods,
            which can result from weight gain and increases in body mass index
            (BMI) and body fat percentage, or extreme weight loss and an
            unhealthy low percent body fat. Eating disorders such as anorexia
            nervosa or bulimia also will result in hormonal imbalances and
            missed periods, called amenorrhea.
          </p>

          <p className="typography-p mb-2">
            Excessive and intense exercise that significantly decreases a
            woman’s body fat percentage and energy metabolism will influence
            hormone production and alter her menstrual cycle patterns. Indeed,
            it is not uncommon for high performance athletes to stop
            menstruating altogether.
          </p>
          <p className="typography-p mb-2">[Place for image]</p>

          <p className="typography-p mb-2">
            The reference intervals for healthy BMI and percent body fat for
            women and men by age is shown in the table below. A healthy body fat
            percentage for a woman in her reproductive years between around
            20-40, should be approximately 21– 33%.
          </p>

          <p className="typography-p mb-4">[Place for image]</p>

          <h4 className="typography-subtitle mb-4">
            Polycystic Ovary Syndrome (PCOS)
          </h4>
          <p className="typography-p mb-2">
            PCOS is the most common endocrine disorder and most likely affects
            anywhere from 8-18% of all women of reproductive age worldwide. The
            highest prevalence is associated with Hispanic women. (2) It is the
            number one cause of infertility affecting all women seeking IVF.
          </p>

          <p className="typography-p mb-2">
            PCOS is most widely diagnosed using the Rotterdam criteria, which
            requires the presence of at least two of the following signs:
          </p>

          <ul className="typography-p mb-2">
            <li>
              irregular cycles, otherwise called oligomenorrhea / anovulation,
              and/or
            </li>
            <li>clinical and/or biochemical hyperandrogenism, and/or</li>
            <li>
              polycystic ovaries on ultrasound or high ovarian volume (enlarged
              ovaries)
            </li>
          </ul>
          <p className="typography-p mb-2">
            Irregular cycles is one of the top three reasons that women who are
            affected by PCOS first seek advice from their healthcare provider.
            Women with PCOS tend to have longer cycles and heavier periods when
            they do menstruate. This is caused when E2 and P4 levels remain high
            and the uterine lining continues to build up until it can no longer
            sustain itself. Once the heavy lining detaches, it results in a
            heavy and long period flow. The other top reasons are weight gain
            and hirsutism, growth of unwanted body hair caused by
            hyperandrogenism. Up to 70 percent of PCOS-affected women are
            insulin resistant, i.e., Type 2 diabetic or prediabetic, meaning
            that their cells can’t use insulin properly. (3) Eighty percent
            (80%) or more of PCOS women are morbidly obese. (4) Hirsutism is
            defined by clinical examination using the modified Ferriman-Gallwey
            (mFG) scoring system, or as biochemical hyperandrogenism using serum
            tests for androgens. A score of >8 is considered hirsutism among
            Western women and should be investigated further. (5) Earlier
            studies report a Ferriman-Gallwey score >15 was associated with a
            high detection rate of PCOS (up to 70%). (6,7)
          </p>

          <p className="typography-p mb-4">
            PCOS has multiple phenotypes and is complicated to diagnose. In
            fact, the World Health Organization and the Centers for Disease
            Control estimate 70% of PCOS cases are undiagnosed. (ref: Polycystic
            Ovary Syndrome (PCOS). (2019)
          </p>

          <h4 className="typography-subtitle mb-4">
            Perimenopause / Menopause transition
          </h4>
          <p className="typography-p mb-2">
            The average age at natural menopause in a normal U.S. population is
            approximately 51 years. The clinical definition of menopause is 12
            months of consecutive amenorrhea (i.e., no menstrual periods).
            Menopause signals the depletion of the ovarian follicle pool.
            Without follicles, there is no more ovulation, and so there is no
            longer a period. The perimenopausal transition for most women can
            begin as early as 10 years prior to her age at which she has her
            final menstrual period. Her cycles will become increasingly
            irregular from her early 40s. [Insert picture of age-related decline
            in follicles and AMH vs. Age.]
          </p>
          <p className="typography-p mb-2">[Place for image]</p>
          <p className="typography-p mb-2">
            FSH levels, and other hormones, begin to fluctuate wildly. E2 and
            AMH levels decline. At menopause, Estradiol and AMH levels become
            undetectable and FSH levels increase dramatically.
          </p>
          <p className="typography-p mb-4">
            In future installments, we will discuss the differences between
            natural and unnatural menopause and related conditions including
            primary ovarian insufficiency, premature ovarian failure, and causes
            of ovarian failure due to exposure to toxic substances, i.e.,
            gonadotoxicity.
          </p>

          <h4 className="typography-subtitle mb-4">Medication</h4>
          <p className="typography-p mb-4">
            If you are using a hormonal contraceptive, then you will have a
            consistently regularly timed period cycle after cycle. Hormonal
            contraceptives inhibit ovulation and therefore prevent pregnancy.
            They mask cycle irregularities by providing a consistent dose of
            steroid hormones, which is why they are commonly prescribed to women
            experiencing irregular cycles. The problem that arises when a woman
            decides to go off her prescribed hormonal contraception, is that her
            cycles become irregular again, and she usually encounters issues
            trying to conceive. Many prescribed and over-the-counter medications
            also can cause irregular cycles by influencing the production of
            estrogen and progesterone throughout the cycle.
          </p>

          <h4 className="typography-subtitle mb-4">MotiveBio</h4>
          <p className="typography-p mb-4">
            We are dedicated to helping women better understand their fertility
            health so that you know why and when to see your doctor. We offer
            affordable testing with an easy to use at-home collection kit to
            collect a fingerstick blood sample conveniently in the privacy of
            your own home. Our fertility health web application enables you to
            monitor menstrual cycle health, physical symptoms. We combine this
            information with your hormone test results and generate a
            personalized fertility health assessment that you can share with
            your doctor.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h4 className="typography-subtitle mb-4">References</h4>

          <ol className="typography-p mb-4">
            <li>
              Singer, Katie (2004). The Garden of Fertility. New York: Avery, a
              member of Penguin Group (USA). pp. 226–7.
            </li>
            <li>
              Wolf, W. M., Wattick, R. A., Kinkade, O. N., & Olfert, M. D.
              (2018). Geographical Prevalence of Polycystic Ovary Syndrome as
              Determined by Region and Race/Ethnicity. International journal of
              environmental research and public health, 15(11), 2589.
              doi:10.3390/ijerph15112589.
            </li>
            <li>
              Marshall, J. C., & Dunaif, A. (2012). Should all women with PCOS
              be treated for insulin resistance? Fertility and sterility, 97(1),
              18–22. doi:10.1016/j.fertnstert.2011.11.036.
            </li>
            <li>
              Sam S. (2007). Obesity and Polycystic Ovary Syndrome. Obesity
              management, 3(2), 69–73. doi:10.1089/obe.2007.0019.
            </li>
            <li>
              Martin KA, et al. J Clin Endocrinol Metab. 2018;
              doi:10.1210/jc.2018-00241.
            </li>
            <li>
              Lujan, M. E., Chizen, D. R., & Pierson, R. A. (2008). Diagnostic
              criteria for polycystic ovary syndrome: pitfalls and
              controversies. Journal of obstetrics and gynaecology Canada: JOGC
              = Journal d'obstetrique et gynecologie du Canada: JOGC, 30(8),
              671–679. doi:10.1016/S1701-2163(16)32915-2.
            </li>
            <li>
              Aswini, R., & Jayapalan, S. (2017). Modified Ferriman-Gallwey
              Score in Hirsutism and its Association with Metabolic Syndrome.
              International journal of trichology, 9(1), 7–13.
              doi:10.4103/ijt.ijt_93_16.
            </li>
          </ol>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(styles, { withTheme: true })(
  withRoot(MenstrualCycleData)
);
